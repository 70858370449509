export function numberFormat(num: number | null, decimals = 2): string {
  if (num === null || num === 0) return '';
  return parseFloat(num as any)
    .toFixed(decimals)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function numberAndNullFormat(num: number | null, decimals = 2): string | null {
  if (num === null || num === 0) return null;
  return parseFloat(num as any)
    .toFixed(decimals)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function numberPercentageFormat(num: number | null): number | null {
  if (num === null || num === 0) return null;
  return parseFloat(num as any) * 100;
}

export function currencyFormat(num: number | null): string {
  if (num === null || num === 0) return '$0.00';
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function currencyFormatInt(num: number | null): string {
  if (num === null || num === 0) return '$0';
  return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function phoneFormat(phoneNumber: string): string {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
}

export function cleanPhoneFormat(phoneNumber: string): string {
  return ('' + phoneNumber).replace(/\D/g, '');
}
