import * as React from 'react';

import { Form } from 'react-bootstrap';
import { useSearch } from './SearchContext';

export const EmailNextYear: React.FunctionComponent = () => {
  const { state, actions } = useSearch();
  const [email, setEmail] = React.useState('');

  return (
    <>
      {state.notifyNextYearComplete ? (
        <p className="p-xl">Thanks, see you next year.</p>
      ) : (
        <Form className="contact-form d-grid">
          <Form.Group className="form-group" controlId="email">
            <Form.Control
              type="text"
              placeholder="Email Address"
              style={{ marginBottom: 10 }}
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
          </Form.Group>
          <button className="btn btn-yellow yellow-hover btn-lg" type="button" onClick={() => actions.notifyMeNextYear(email)}>
            Notify Me
          </button>
        </Form>
      )}
    </>
  );
};
