import * as React from 'react';

import { Form } from 'react-bootstrap';
import { useSearch } from './SearchContext';

export const PlaybookIdSearch: React.FunctionComponent = () => {
  const { state, actions } = useSearch();

  return (
    <Form.Group className="form-group mb-20" controlId="playbookId">
      <p className="p-lg">Playbook ID (If you received a letter from us)</p>
      <Form.Control
        type="text"
        className="mb-0"
        value={state.selectedPlaybookId}
        onChange={(event) => actions.savePlaybookId(event.target.value)}
        isInvalid={state.searchResult ? state.selectedPlaybookId && !state.searchResult.hasGoodSitusId : false}
      />
      <Form.Control.Feedback type="invalid">Invalid Playbook ID</Form.Control.Feedback>
    </Form.Group>
  );
};
