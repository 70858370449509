import * as React from 'react';

import { Col, Row } from 'react-bootstrap';

import { EmailNextYear } from './EmailNextYear';
import { useSearch } from './SearchContext';

export const BuyNothing: React.FunctionComponent = () => {
  const { state } = useSearch();

  if (state.searchResultType !== 'Nothing') {
    return <></>;
  }

  return (
    <Row>
      <Col md={12}>
        <div className="bg-tra-purple p-4 mb-20" style={{ borderRadius: 16 }}>
          <Row>
            <Col md={8}>
              <h3 className="purple-color">Unfortunately, Property Tax Playbooks are not yet available in your county.</h3>
              <p className="p-xl">Would you like us to analyze your property next year and let you know our findings?</p>
              <p className="p-xl">We don't spam you all year, we will just analyze your property when new values are released.</p>
            </Col>
            <Col md={4} className="text-end">
              <EmailNextYear />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
