import * as React from 'react';

import { Col, Row } from 'react-bootstrap';

import { EmailNextYear } from './EmailNextYear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScaleBalanced } from '@fortawesome/pro-light-svg-icons';
import { useSearch } from './SearchContext';

export const BuyTips: React.FunctionComponent = () => {
  const { state } = useSearch();

  if (state.searchResultType !== 'Tips') {
    return <></>;
  }

  return (
    <Row>
      <Col md={12}>
        <div className="bg-tra-purple p-4 mb-20" style={{ borderRadius: 16 }}>
          <h3 className="purple-color">
            You Are Fairly Valued <FontAwesomeIcon icon={faScaleBalanced} className="purple-color" />
          </h3>
          <p className="p-xl mb-30">
            Our expert analysis shows that your property is fairly valued this year. This means a customized Playbook for your property would probably not
            support a value reduction this tax season.
          </p>
          <hr />
          {/* <Row>
            <Col md={8}>
              <h3 className="purple-color">Still planning on protesting? How can we help?</h3>
              <p className="p-xl">
                Tips &amp; Tricks to help guide you through your appeal.
                <ul>
                  <li>Step-by-step instructions to walk you through the process</li>
                  <li>Preparation guides for how to be successful</li>
                  <li>Annual property analysis to show if you're over valued</li>
                </ul>
              </p>
            </Col>
            <Col md={4} className="text-end">
              <h2 className="h2-title-xs">$25</h2>
              <button className="btn btn-yellow yellow-hover" type="button">
                Purchase Tips &amp; Tricks
              </button>
            </Col>
          </Row>
          <hr /> Not going to protest?*/}
          <Row>
            <Col md={8}>
              <h3 className="purple-color">Get Notified of Your 2023 Assessment</h3>
              <p className="p-xl">Would you like us to analyze your property next year and let you know our findings?</p>
              <p className="p-xl">We don't spam you all year, we will just analyze your property when new values are released.</p>
            </Col>
            <Col md={4}>
              <EmailNextYear />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
