import * as React from 'react';

import { AddressItem, useSearch } from './SearchContext';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';

export const AddressSearch: React.FunctionComponent = () => {
  const {
    state: { addressItems, canSearchAddress, addressSearchLoading, selectedAddress, selectedAddressText, selectedState, selectedCounty },
    actions,
  } = useSearch();

  const filterBy = () => true;

  return (
    <Form.Group className="form-group" controlId="address">
      <p className="p-lg">Physical Address</p>
      {!canSearchAddress && (
        <Form.Control
          type="text"
          value={selectedAddressText}
          onChange={(event) => actions.saveAddressText(event.target.value)}
          disabled={selectedState === 0 || selectedCounty === 0}
        />
      )}
      {canSearchAddress && (
        <AsyncTypeahead
          filterBy={filterBy}
          id="address"
          multiple={false}
          isLoading={addressSearchLoading || false}
          labelKey="situsAddress"
          minLength={3}
          disabled={selectedState === 0 || selectedCounty === 0}
          onChange={(selected: any) => selected && actions.saveAddress(selected[0])}
          selected={selectedAddress ? ([selectedAddress] as any) : []}
          onSearch={(query) => actions.searchAddress(query)}
          options={addressItems || ([] as AddressItem[])}
          renderMenuItemChildren={(option: any, props) => {
            return (
              <React.Fragment>
                <div className="pt-1">{option.situsAddress}</div>
                <div className="fst-italic mb-1" style={{ fontSize: '.75rem' }}>
                  {option.ownerName}
                </div>
                <div className="border-bottom border-2 ms-n3 me-n3"></div>
              </React.Fragment>
            );
          }}
        />
      )}
    </Form.Group>
  );
};
