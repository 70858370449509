import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { useSearch } from './SearchContext';

export const SearchButton: React.FunctionComponent = () => {
  const { state, actions } = useSearch();

  return (
    <button
      className="btn btn-yellow yellow-hover btn-lg"
      type="button"
      disabled={state.searchLoading}
      onClick={() => {
        actions.submitSearch();
      }}
    >
      {state.searchLoading ? (
        <>
          <FontAwesomeIcon icon={faLoader} spin /> Searching...
        </>
      ) : (
        'Start Search'
      )}
    </button>
  );
};
