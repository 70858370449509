import * as React from 'react';

import Select, { ActionMeta, StylesConfig } from 'react-select';
import { StateItem, useSearch } from './SearchContext';

import { Form } from 'react-bootstrap';

export const StateList: React.FunctionComponent = () => {
  const {
    state: { stateItems, selectedState },
    actions,
  } = useSearch();

  React.useEffect(() => {
    actions.loadStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const colourStyles: StylesConfig<StateItem> = {
    control: (styles) => ({ ...styles, minHeight: 60, fontSize: '1.1rem' }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
  };

  return (
    <Form.Group className="form-group" controlId="state">
      <p className="p-lg">State</p>
      <Select
        className="mb-4"
        id="state"
        name="state"
        styles={colourStyles}
        options={stateItems}
        getOptionLabel={(option: StateItem) => `${option.name}`}
        getOptionValue={(option: StateItem) => `${option.id}`}
        isSearchable={true}
        isMulti={false}
        value={stateItems ? stateItems.find((option) => option.id === selectedState.toString()) : null}
        onChange={(newValue: StateItem, actionMeta: ActionMeta<StateItem>) => actions.selectState(newValue.id)}
      />
    </Form.Group>
  );
};
