import * as React from 'react';

import { AddressSearch, BuyNothing, BuyPlaybook, BuyTips, CountyList, SearchButton, SearchProvider, StateList } from '../components/search';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Footer, NavMenu, PageTitle } from '../components/shared';
import { faCircle1, faCircle2, faCircle3 } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlaybookIdSearch } from '../components/search/PlaybookIdSearch';
import { useScreenInfo } from '../utils/ScreenInfo';

export const SearchPage = () => {
  const screen = useScreenInfo();
  return (
    <SearchProvider>
      <PageTitle subtitle="Search" />
      <NavMenu fixed noNav />
      <Container className="wsmainwp" style={{ minHeight: '81vh' }}>
        <Row className="mb-50 mt-50">
          <Col md={12} className="text-center">
            <h2>Search for your property to determine if you're over valued.</h2>
            <h3>If there are tax savings to be won, our personalized Playbook will show you the way!</h3>
          </Col>
        </Row>
        <Row className="mb-50 mt-50">
          <Col md={8}>
            <div className="bg-whitesmoke p-4" style={{ borderRadius: 16 }}>
              <h1>Let's Find Your Property</h1>

              <Form className="contact-form">
                <div className="row">
                  <div className="col-lg-6">
                    <StateList />
                  </div>
                  <div className="col-lg-6">
                    <CountyList />
                  </div>
                </div>
                <AddressSearch />
                <h2 className="search-or">
                  <span>OR</span>
                </h2>
                <PlaybookIdSearch />
                <div className="d-grid">
                  <SearchButton />
                  <p className="p-xl text-center mt-10">
                    Having trouble finding your property? Shoot us an email at{' '}
                    <a className="purple-color tra-purple-hover" href="mailto:support@propertytaxplaybook.com">
                      support@propertytaxplaybook.com
                    </a>{' '}
                    and we can help.
                  </p>
                </div>
              </Form>
            </div>
          </Col>
          <Col md={4}>
            {!screen.isPhone && (
              <div className="text-center">
                <FontAwesomeIcon icon={faCircle1} size="3x" className="purple-color mb-10 mt-60" style={{ fontSize: '3rem' }} />
                <h5 className="h5-md mb-20">Search Your Property</h5>
                <FontAwesomeIcon icon={faCircle2} size="3x" className="purple-color mb-10 mt-60" style={{ fontSize: '3rem' }} />
                <h5 className="h5-md mb-20">
                  Secure Your <br />
                  Custom-Built Playbook
                </h5>
                <FontAwesomeIcon icon={faCircle3} size="3x" className="purple-color mb-10 mt-60" style={{ fontSize: '3rem' }} />
                <h5 className="h5-md mb-20">Be Empowered to Appeal</h5>
              </div>
            )}
          </Col>
        </Row>
        <BuyPlaybook />
        <BuyTips />
        <BuyNothing />
      </Container>
      <Footer />
    </SearchProvider>
  );
};

export default SearchPage;
