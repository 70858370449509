import * as React from 'react';

import { CountyItem, useSearch } from './SearchContext';
import Select, { ActionMeta, StylesConfig } from 'react-select';

import { Form } from 'react-bootstrap';

export const CountyList: React.FunctionComponent = () => {
  const {
    state: { countyItems, selectedCounty },
    actions,
  } = useSearch();

  const colourStyles: StylesConfig<CountyItem> = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#dee2e6' : '#fff',
      borderColor: state.isDisabled ? '#a6a9ac' : 'hsl(0, 0%, 80%)',
      minHeight: 60,
      fontSize: '1.1rem',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
  };

  const isSelectedCounty = (option) => option.id === selectedCounty.toString();

  return (
    <Form.Group className="form-group" controlId="county">
      <p className="p-lg">County</p>
      <Select
        className="mb-4"
        id="county"
        name="county"
        styles={colourStyles}
        options={countyItems}
        getOptionLabel={(option: CountyItem) => `${option.name}`}
        getOptionValue={(option: CountyItem) => `${option.id}`}
        isSearchable={true}
        isMulti={false}
        value={countyItems && countyItems.findIndex(isSelectedCounty) !== -1 ? countyItems.find(isSelectedCounty) : null}
        onChange={(newValue: CountyItem, actionMeta: ActionMeta<CountyItem>) => actions.selectCounty(newValue.id)}
        isDisabled={countyItems.length === 0}
      />
    </Form.Group>
  );
};
