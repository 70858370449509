import React, { Reducer } from 'react';

import { getApiClient } from '../../utils/GetApiClient';
import { saveState } from '../../utils/Session';

const sessionKey = 'search';

// BASE STATE
export interface SearchState {
  stateItems: StateItem[];
  selectedState: number;
  countyItems: CountyItem[];
  selectedCounty: number;
  canSearchAddress: boolean;
  addressItems: AddressItem[];
  selectedAddress: AddressItem;
  selectedAddressText: string;
  addressSearchLoading: boolean;
  selectedPlaybookId: string;
  searchLoading: boolean;
  searchResult: SearchResult;
  searchResultType: 'Playbook' | 'Tips' | 'Nothing';
  notifyNextYearComplete: boolean;
}

// API RESPONSE
export type StateItem = {
  id: string;
  abbreviation: string;
  name: string;
};

export type CountyItem = {
  id: string;
  stateId: string;
  name: string;
};

export type AddressItem = {
  situsId: string;
  situsAddress: string;
  ownerName: string;
};

export type SearchResult = {
  hasGoodGrid: boolean;
  isExpired: boolean;
  isOperational: boolean;
  amountOverValued: number;
  hasGoodSitusId: boolean;
  situsId: string;
  situsAddress: string;
  ownerName: string;
  forceAffirmation: boolean;
};

export const defaultState: SearchState = {
  stateItems: [],
  selectedState: 0,
  countyItems: [],
  selectedCounty: 0,
  canSearchAddress: false,
  addressItems: [],
  selectedAddress: null,
  selectedAddressText: '',
  addressSearchLoading: false,
  selectedPlaybookId: '',
  searchLoading: false,
  searchResult: null,
  searchResultType: null,
  notifyNextYearComplete: false,
};

// REDUCER
export enum SearchReducerTypes {
  SaveStates,
  SaveSelectedState,
  SaveCounties,
  SaveSelectedCounty,
  SaveCanSearchAddress,
  AddressSearchLoading,
  SaveAddressList,
  SearchLoading,
  SaveAddress,
  SavePlaybookId,
  SaveAddressText,
  SaveSearchResult,
  SetNotifyNextYearComplete,
}

interface SearchActions {
  loadStates(): void;
  selectState(id: string);
  selectCounty(id: string): void;
  searchAddress(query: string): void;
  saveAddress(address: AddressItem): void;
  savePlaybookId(address: string): void;
  saveAddressText(address: string): void;
  submitSearch(): void;
  transferToCheckout(productId: number);
  notifyMeNextYear(email: string);
}

interface SearchContextValue {
  state: SearchState;
  dispatch: React.Dispatch<any>;
  actions: SearchActions;
}

const SearchContext = React.createContext<SearchContextValue>({
  state: {} as SearchState,
  dispatch: () => null,
  actions: {
    loadStates: () => null,
    selectState: () => null,
    selectCounty: () => null,
    searchAddress: () => null,
    saveAddress: () => null,
    savePlaybookId: () => null,
    saveAddressText: () => null,
    submitSearch: () => null,
    transferToCheckout: () => null,
    notifyMeNextYear: () => null,
  },
});

type ReducerAction = {
  type: SearchReducerTypes;
  payload: any;
};

const searchStateReducer: Reducer<SearchState, ReducerAction> = (state: SearchState, action: any) => {
  switch (action.type) {
    case SearchReducerTypes.SaveStates: {
      action.payload.unshift({ id: '0', abbreviation: '', name: ' ' });
      return saveState(sessionKey, { ...state, stateItems: action.payload });
    }
    case SearchReducerTypes.SaveSelectedState: {
      return saveState(sessionKey, {
        ...state,
        selectedState: action.payload,
        selectedCounty: 0,
        countyItems: [],
        selectedAddressText: '',
        selectedAddress: null,
        searchResult: null,
        searchResultType: null,
        selectedPlaybookId: null,
      });
    }
    case SearchReducerTypes.SaveCounties: {
      return saveState(sessionKey, { ...state, countyItems: action.payload });
    }
    case SearchReducerTypes.SaveSelectedCounty: {
      return saveState(sessionKey, {
        ...state,
        selectedCounty: action.payload,
        canSearchAddress: false,
        selectedAddressText: '',
        selectedAddress: null,
        searchResult: null,
        searchResultType: null,
      });
    }
    case SearchReducerTypes.AddressSearchLoading: {
      return saveState(sessionKey, { ...state, addressSearchLoading: true });
    }
    case SearchReducerTypes.SaveAddressList: {
      return saveState(sessionKey, { ...state, addressItems: action.payload, playbookIdItems: null, addressSearchLoading: false });
    }
    case SearchReducerTypes.SaveCanSearchAddress: {
      return saveState(sessionKey, { ...state, canSearchAddress: action.payload });
    }
    case SearchReducerTypes.SaveAddress: {
      return saveState(sessionKey, {
        ...state,
        selectedAddress: action.payload,
        selectedPlaybookId: '',
        selectedAddressText: '',
        searchResult: null,
        searchResultType: null,
      });
    }
    case SearchReducerTypes.SavePlaybookId: {
      return saveState(sessionKey, {
        ...state,
        selectedPlaybookId: action.payload,
        selectedState: 0,
        selectedCounty: 0,
        selectedAddress: null,
        selectedAddressText: '',
        searchResult: null,
        searchResultType: null,
      });
    }
    case SearchReducerTypes.SaveAddressText: {
      return saveState(sessionKey, {
        ...state,
        selectedAddressText: action.payload,
        selectedPlaybookId: '',
        selectedAddress: null,
        searchResult: null,
        searchResultType: null,
      });
    }
    case SearchReducerTypes.SearchLoading: {
      return saveState(sessionKey, { ...state, searchLoading: true });
    }
    case SearchReducerTypes.SaveSearchResult: {
      var resultType = 'Nothing';
      if (action.payload.hasGoodGrid) {
        resultType = 'Playbook';
      }

      if (!action.payload.hasGoodGrid && state.canSearchAddress) {
        resultType = 'Tips';
      }

      if (state.selectedPlaybookId && !action.payload.hasGoodSitusId) {
        resultType = null;
      }

      return saveState(sessionKey, { ...state, searchResult: action.payload, searchResultType: resultType, searchLoading: false });
    }
    case SearchReducerTypes.SetNotifyNextYearComplete: {
      return saveState(sessionKey, { ...state, notifyNextYearComplete: true });
    }
    default:
      return state;
  }
};

interface SearchProviderProps {
  children: React.ReactNode;
}

export const SearchProvider: React.FunctionComponent<SearchProviderProps> = (props: SearchProviderProps) => {
  // let initialState = loadState(sessionKey);
  // if (initialState === undefined) {
  //   initialState = defaultState;
  // }

  saveState(sessionKey, defaultState);

  const [state, dispatch] = React.useReducer(searchStateReducer, defaultState);

  function loadStates(): void {
    getApiClient()
      .get<any>('/api/search/states')
      .then((response) => {
        dispatch({ type: SearchReducerTypes.SaveStates, payload: response.data.value });
      })
      .catch((error) => console.log(error));
  }

  function selectState(id: string): void {
    dispatch({ type: SearchReducerTypes.SaveSelectedState, payload: parseInt(id) });

    getApiClient()
      .get<any>('/api/search/counties/' + id)
      .then((response) => {
        dispatch({ type: SearchReducerTypes.SaveCounties, payload: response.data.value });
      })
      .catch((error) => console.log(error));
  }

  function selectCounty(id: string): void {
    dispatch({ type: SearchReducerTypes.SaveSelectedCounty, payload: parseInt(id) });

    if (state.selectedState === 44) {
      var request = {
        searchTerm: '',
        stateId: state.selectedState,
        countyId: id,
      };

      getApiClient()
        .post<any>('/api/search/properties', request)
        .then((response) => {
          dispatch({ type: SearchReducerTypes.SaveCanSearchAddress, payload: response.data.value.length > 0 });
        })
        .catch((error) => console.log(error));
    }
  }

  function searchAddress(query: string): void {
    dispatch({ type: SearchReducerTypes.AddressSearchLoading, payload: null });

    var request = {
      searchTerm: query,
      stateId: state.selectedState,
      countyId: state.selectedCounty,
    };

    getApiClient()
      .post<any>('/api/search/properties', request)
      .then((response) => {
        dispatch({ type: SearchReducerTypes.SaveAddressList, payload: response.data.value });
      })
      .catch((error) => console.log(error));
  }

  function saveAddress(address: AddressItem): void {
    dispatch({ type: SearchReducerTypes.SaveAddress, payload: address });
  }

  function savePlaybookId(address: string): void {
    dispatch({ type: SearchReducerTypes.SavePlaybookId, payload: address });
  }

  function saveAddressText(address: string): void {
    dispatch({ type: SearchReducerTypes.SaveAddressText, payload: address });
  }

  function submitSearch(): void {
    if (!state.selectedPlaybookId && !state.selectedAddress?.situsAddress && !state.selectedAddressText) {
      return;
    }

    dispatch({ type: SearchReducerTypes.SearchLoading, payload: null });
    var request = {};

    if (state.selectedPlaybookId) {
      request = {
        searchTerm: state.selectedPlaybookId,
        stateId: 0,
        countyId: 0,
        situsId: state.selectedPlaybookId,
        isSitusIdSearch: true,
      };
    } else {
      request = {
        searchTerm: state.canSearchAddress ? state.selectedAddress.situsAddress : state.selectedAddressText,
        stateId: state.selectedState,
        countyId: state.selectedCounty,
        situsId: state.canSearchAddress ? state.selectedAddress.situsId : '',
        isSitusIdSearch: false,
      };
    }

    getApiClient()
      .post<any>('/api/search/properties/submit', request)
      .then((response) => {
        dispatch({ type: SearchReducerTypes.SaveSearchResult, payload: response.data });
        window.scrollTo(0, document.body.scrollHeight);
      })
      .catch((error) => console.log(error));
  }

  function transferToCheckout(productId: number): void {
    saveState('checkout', {
      situsId: state.searchResult.situsId,
      productId: 1001,
      situsAddress: state.searchResult.situsAddress,
      ownerName: state.searchResult.ownerName,
    });

    document.location.href = '/checkout';
  }

  function notifyMeNextYear(email: string): void {
    var request = {
      email: email,
      stateId: state.selectedState,
      countyId: state.selectedCounty,
      address: state.selectedAddress ? state.selectedAddress.situsAddress : state.selectedAddressText,
      situsId: state.selectedAddress ? state.selectedAddress.situsId : '',
    };

    getApiClient()
      .post<any>('/api/future-clients/create', request)
      .then((response) => {
        dispatch({ type: SearchReducerTypes.SetNotifyNextYearComplete, payload: null });
      })
      .catch((error) => console.log(error));
  }

  return (
    <SearchContext.Provider
      value={{
        state,
        dispatch,
        actions: {
          loadStates,
          selectState,
          selectCounty,
          searchAddress,
          saveAddress,
          savePlaybookId,
          saveAddressText,
          submitSearch,
          transferToCheckout,
          notifyMeNextYear,
        },
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export const useSearch = (): SearchContextValue => React.useContext(SearchContext);
