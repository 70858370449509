import * as React from 'react';

import { Col, Form, Row } from 'react-bootstrap';

import { EmailNextYear } from './EmailNextYear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currencyFormatInt } from '../../utils/Formaters';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons';
import { useSearch } from './SearchContext';

export const BuyPlaybook: React.FunctionComponent = () => {
  const { state, actions } = useSearch();
  const [affirmYes, setAffirmYes] = React.useState(false);
  if (state.searchResultType !== 'Playbook') {
    return <></>;
  }

  return (
    <>
      {state.searchResult.forceAffirmation && (
        <Row>
          <Col md={12}>
            <div className="bg-tra-red p-4 mb-20" style={{ borderRadius: 16 }}>
              <Row>
                <Col md={8}>
                  <h3 className="red-color">By checking this box, you are affirming that you have already filed your appeal with the appraisal district.</h3>
                  <p className="p-xl">
                    If you haven't already filed your appeal, then our Playbook can't help you this year since you have missed the filing deadline.
                  </p>
                </Col>
                <Col md={4} className="text-end">
                  <h6 className="h6-xl mb-0" style={{ fontWeight: 'normal', color: '#6c757d', letterSpacing: 'normal' }}>
                    I Affirm.
                  </h6>
                  <Form.Check type="checkbox" id="custom-switch2" className="big-check" checked={affirmYes} onChange={() => setAffirmYes(!affirmYes)} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <div className="bg-tra-purple p-4 mb-20" style={{ borderRadius: 16 }}>
            <Row>
              <Col md={8}>
                <h3 className="purple-color">
                  You Are Over Valued <FontAwesomeIcon icon={faPiggyBank} className="purple-color" />
                </h3>
                <div className="div-xl mb-10">
                  Our expert analysis shows that your property is over valued by approximately {currencyFormatInt(state.searchResult.amountOverValued)}.
                </div>
                <div className="div-xl mb-10">Your property-specific Playbook can help you appeal this assessment.</div>
                <div className="div-xl mb-10">It's time to fight for the value reduction you deserve.</div>
                <div className="div-xl">
                  <strong className="purple-color">What You Get in Your Playbook</strong>
                  <div className="pl-10">
                    - <strong>Prefilled</strong> notice of protest and appeal forms
                  </div>
                  <div className="pl-40"> - You just sign &amp; mail it in!</div>
                  <div className="pl-10">
                    - <strong>Customized Evidence</strong> to support a property assessment reduction
                  </div>
                  <div className="pl-40"> - Market Value and Equal &amp; Uniform appeal evidence</div>
                  <div className="pl-10">
                    - <strong>Instructions</strong> guiding you through your property tax appeal
                  </div>
                </div>
              </Col>
              <Col md={4} className="text-end">
                <h2 className="h2-title-xs">$100</h2>
                <button
                  className="btn btn-yellow yellow-hover"
                  type="button"
                  onClick={() => actions.transferToCheckout(1001)}
                  disabled={!affirmYes && state.searchResult.forceAffirmation}
                >
                  Purchase Playbook
                </button>
                {!affirmYes && state.searchResult.forceAffirmation && <p className="p-xl text-red pl-100 mt-10">You must check the box above to continue.</p>}
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="purple-color fst-italic mt-20">We've done all the prep work, all you have to do is print your Playbook &amp; mail it in!</h3>
              </Col>
            </Row>
          </div>
          <div className="bg-tra-purple p-4 mb-20" style={{ borderRadius: 16 }}>
            <Row>
              <Col md={8}>
                <h3 className="purple-color">Get Notified of Your 2023 Assessment</h3>
                <p className="p-xl">Would you like us to analyze your property next year and let you know our findings?</p>
                <p className="p-xl">We don't spam you all year, we will just analyze your property when new values are released.</p>
              </Col>
              <Col md={4}>
                <EmailNextYear />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
